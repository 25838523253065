<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm
      title="Sign in to JDoodle"
      in-progress-message="JDoodle is trying to sign you in, Please wait..."
      success-message="Successfully Signed In."
      action-text="Sign In"
      :form="doodleForm"
      :inProgress="doodleForm.inProgress"
      :completed="doodleForm.completed"
      :errorMessageText="doodleForm.errorMessage"
      @action="recaptchaAction"
      @close="$refs.sideBar.emitToggle()"
    >
      <p class="recommended">We recommended using</p>
      <div class="google_or_mic">
        <div class="google" @click="googleSignIn">
          <img src="../assets/images/header/google.svg" />
          <p>Sign in with Google</p>
        </div>
        <div class="mic" @click="microsoftSignIn">
          <img src="../assets/images/header/microsoft.svg" />
          <p>Sign in with Microsoft</p>
        </div>
      </div>

      <div class="or">
        <span></span>
        <p>or</p>
        <span></span>
      </div>
      <div class="level is-mobile register">
        <div class="level-item has-text-black new-to-message  ">
          <span>New to JDoodle? </span>
          &nbsp;&nbsp;
          <a
            @click="$emit('changeComponent', 'Register')"
            class="is-underlined"
          >
            Sign up
          </a>
        </div>
      </div>
      <DoodleInput
        field-name="username"
        label-text="Email ID"
        type="text"
        leftIcon="envelope"
        v-model="doodleForm.username"
        @enter="recaptchaAction"
        rule="required|email"
        :model="doodleForm.username"
        place-holder="joe@example.com"
      />
      <div class="level is-mobile register">
        <div class="level-item has-text-black new-to-message  ">
          <a @click="$emit('changeComponent', 'ForgotPassword')">
            Forget your password?
          </a>
        </div>
      </div>
      <DoodleInput
        field-name="password"
        label-text="Password"
        type="password"
        leftIcon="lock"
        v-model="doodleForm.password"
        @enter="recaptchaAction"
        rule="required"
        :model-name="doodleForm.password"
        place-holder="************"
        class="password"
      />
      <div
        id="loginContainer"
        class="g-recaptcha"
        data-size="invisible"
        data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi"
      ></div>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import oauthMixin from '../assets/javascript/oauth-mixin'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'
import DoodleInput from './Utils/DoodleInput'
import DoodleGeneralForm from './Utils/DoodleGeneralForm'
import SideBar from './Utils/SideBar'
import { eventBus } from './../assets/javascript/event-bus'
import { JD_CONST } from '../assets/javascript/constants'

export default {
  name: 'login',
  mixins: [formMixin, oauthMixin, recaptchaMixin],
  inject: ['$validator'],
  components: { SideBar, DoodleGeneralForm, DoodleInput },
  data: function () {
    return {
      doodleForm: {
        username: null,
        password: null
      }
    }
  },
  mounted () {
    if (this.$store.state.isUserLoggedIn === true) {
      this.$router.push('/')
    }
    this.loadRecaptcha('loginContainer', this.signIn)
  },
  methods: {
    signIn (gc) {
      if (!gc) {
        this.doodleForm.errorMessage =
          'Robot check failed. Please try refresh the page or contact JDoodle support at hello@jdoodle.com.'
        return
      }

      this.executeAPI({
        url: '/api/login/authenticate',
        data: {
          username: this.doodleForm.username,
          password: this.doodleForm.password,
          recaptcha: gc
        },
        method: 'post',
        successAction: data => {
          if (data.error) {
            this.doodleForm.errorMessage = data.error
            this.doodleForm.completed = false
          } else {
            this.$store.commit('login', data.username)
            eventBus.$emit(JD_CONST.SIGN_IN)
            this.$refs.sideBar.emitToggle()
          }
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'login',
        jdaLabel: ''
      })
      window.grecaptcha.reset()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../node_modules/bulma-divider/dist/css/bulma-divider.min.css";
@import "../assets/style/oauth";

.new-to-message {
  margin-right: 1em;
}
.is-divider {
  margin: 0;
}

.just-registered {
  font-size: 0.8em;
}

.register {
  position: relative;
  div {
    position: absolute;
    right: 0px;
    bottom: -40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #000000;
  }
}
.google_or_mic {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border: 1px solid #30475e;
    border-radius: 5px;
    width: 48%;
    cursor: pointer;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-left: 10px;
    }
  }
}
.recommended {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px;
  color: #000000 !important;
  margin-top: 12px;
}
.or {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 36px;
  span {
    width: 100%;
    border: 1px solid #ebebeb;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
.password {
  margin-top: 27px;
}
@media only screen and (max-width: 550px) {
  .google_or_mic {
    flex-direction: column;
    align-items: center;
    gap: 19px;
    div {
      width: 200px;
      justify-content: center;
    }
  }
  .recommended {
    margin-top: 24px;
    text-align: center;
  }
}
</style>
