import { eventBus } from '@/assets/javascript/event-bus'
import { ROBOT_CONST } from '@/assets/javascript/constants'

export default {
  data: function () {
    return {
      gcId: -1,
      captchaSuccessCallBack: null,
      captchaFailureCallBack: null,
      currentChild: null,
      recheckDuration: 30
    }
  },
  methods: {
    plugOnRequestEventForIde () {
      eventBus.$on(ROBOT_CONST.VALIDATE_ROBOT_CHECK, (data) => {
        this.currentChild = data.requester
        this.callViaCaptcha(this.respondToChildWithSuccess, this.respondToChildWithFailure)
      })
    },
    unplugOnRequestEventForIde () {
      eventBus.$off(ROBOT_CONST.VALIDATE_ROBOT_CHECK)
    },
    respondToChildWithSuccess () {
      eventBus.$emit(this.currentChild, { result: true })
    },
    respondToChildWithFailure () {
      eventBus.$emit(this.currentChild, { result: false })
    },
    loadRecaptcha (container, callbackMethod) {
      let render = (count) => {
        if (count > 50) {
          return
        }

        if (window.grecaptcha && window.grecaptcha.render) {
          this.gcId = window.grecaptcha.render(container, {
            sitekey: '6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi',
            callback: callbackMethod
          })
        } else {
          this.$_.delay(render, 500, count + 1)
        }
      }
      this.$_.delay(render, 10, 1)
    },
    resetRecaptcha (container) {
      window.grecaptcha.reset(container)
    },
    timeSinceLastChecked () {
      if (!this.$store.state.lastRobotCheckedTime) {
        return 0
      }

      return (Date.now() - this.$store.state.lastRobotCheckedTime) / 60000
    },
    callViaCaptcha (callback, failCallback) {
      this.captchaSuccessCallBack = callback
      this.captchaFailureCallBack = failCallback

      if (this.timeSinceLastChecked() > this.recheckDuration) {
        this.checkRobotStatus()
      } else {
        this.callViaCaptchaStep2()
      }
    },
    callViaCaptchaStep2 () {
      if (this.$store.state.robotChecked === false) {
        if (this.gcId > -1) {
          this.resetRecaptcha(this.gcId)
        }
        this.recaptchaAction()
      } else {
        this.captchaSuccessCallBack()
      }
    },
    doRoboCheck (gc) {
      this.executeAPIWitoutValiation({
        url: '/api/doodle/doRobotCheck',
        method: 'post',
        data: {
          gc: gc
        },
        successAction: (data) => {
          this.$store.commit('robotCheckDone')
          this.captchaSuccessCallBack()
        },
        failureAction: () => {
          this.$store.commit('clearRobotCheck')
          this.captchaFailureCallBack()
        },
        markCompleted: false,
        form: this.doodleForm
      })
    },
    clearRobotCheckStatus () {
      this.$store.commit('clearRobotCheck')
    },
    markRobotCheckDone () {
      this.$store.commit('robotCheckDone')
    },
    checkRobotStatus () {
      this.executeAPIWitoutValiation({
        url: '/api/doodle/isRobotChecked',
        method: 'post',
        successAction: (data) => {
          if (data.robotChecked === true) {
            this.markRobotCheckDone()
          } else {
            this.clearRobotCheckStatus()
          }
          this.callViaCaptchaStep2()
        },
        failureAction: () => {
          this.clearRobotCheckStatus()
          this.captchaFailureCallBack()
        },
        markCompleted: false,
        form: this.doodleForm
      })
    },
    recaptchaAction () {
      if (!window.grecaptcha) {
        this.doodleForm.errorMessage = 'Robot check failed. Please try again.'
        return
      }

      this.$validator.validateAll().then((result) => {
        if (result) {
          window.grecaptcha.execute(this.gcId)
        }
      })
    }
  }
}
